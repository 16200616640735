import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { ShopTagResource } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';
import { cloneDeep } from 'lodash-es';
import { shopTagService } from '@/api';
import AddShopTag from './add-shop-tag/add-shop-tag.vue';

@Component({
  name: 'ShopTag',
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, AddShopTag }
})
export default class ShopTag extends Vue {
  public tableOption: OsTableOption<ShopTagResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<ShopTagResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'name',
      label: 'tag.name',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'options',
      label: 'tag.tags',
      minWidth: '100px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'customer:shopTag:save',
      handleClick: (): void => {
        this.openShopTagDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'customer:shopTag:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteShopTag();
      }
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<ShopTagResource> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'customer:shopTag:edit',
        handleClick: (item: ShopTagResource): void => {
          this.openShopTagDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'customer:shopTag:delete',
        handleClick: (item: ShopTagResource): void => {
          this.deleteShopTag(item);
        }
      }
    ]
  };
  public editRow: ShopTagResource | null = null;
  public dialogVisible = false;
  public totalData = 0;
  private selectedRows: Array<ShopTagResource> = [];

  private queryForm: Partial<{
    keywords?: string;
    /**
     * 企业id
     */
    companyId: number;
  }> = {
    keywords: ''
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private companyId = Number(this.$route.query.companyId);

  public activated(): void {
    if (!isNaN(this.companyId) && this.companyId !== Number(this.$route.query.companyId)) {
      this.companyId = Number(this.$route.query.companyId);
      this.reloadData();
    }
  }

  public created(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.shopTagTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<ShopTagResource>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: ShopTagResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public getTags(data: ShopTagResource): Array<string> {
    return data.options.split(',');
  }

  private openShopTagDialog(data: ShopTagResource | null = null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.dialogVisible = true;
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ShopTagResource>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add') {
        x.disabled = value.length === 0;
      }
    });
  }

  private deleteShopTag(data: ShopTagResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await shopTagService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeleteShopTag(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await shopTagService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    this.queryForm.companyId = this.companyId;
    shopTagService
      .getList(this.queryForm as ShopTagResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
